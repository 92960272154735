import React from "react";
import OwlCarousel from "react-owl-carousel3";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";

const BannerOne = (props) => {
    //BannerOne loop start
    const bannerOneData = props.banneronesData.map(
        (bannerOne, index) => (
            <div className={bannerOne.BgClass} key={index}>
                <div className="diplay-table">
                    <div className="display-table-cell">
                        <VisibilitySensor>
                            {({isVisible}) => (
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <span
                                                className={
                                                    isVisible
                                                        ? "hero-text animated fadeInDown slow opacityOne"
                                                        : "opacityZero"
                                                }
                                            >
                                                {bannerOne.TopTitle}
                                            </span>

                                            <h1
                                                className={
                                                    isVisible
                                                        ? "animated fadeInDown slow opacityOne"
                                                        : "opacityZero"
                                                }
                                            >
                                                {bannerOne.Title}
                                            </h1>
                                            <p
                                                className={
                                                    isVisible
                                                        ? "animated fadeInDown slow opacityOne"
                                                        : "opacityZero"
                                                }
                                            >
                                                {bannerOne.Content}
                                            </p>
                                            <div className="center-wrap">
                                                {/* <Link to={bannerOne.BtnLink} className="btn-a">
                                                    <div className="button">
                                                        {bannerOne.BtnName}
                                                        <Icofont icon="icofont-long-arrow-right"/>
                                                        <div className="mask"/>
                                                    </div>
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
            </div>
        )
    );
    //BannerOne loop END

    //Thumbs loop Start
    const thumbdata = props.thumbsData.map((thumb, index) => (
            <div className="owl-thumb-item" key={index}>
                <Icofont icon={thumb.ThumbIcon}/>
                <h3>{thumb.ThumbTitle}</h3>
                <p>{thumb.ThumbContent}</p>
            </div>
        )
    );
    //Thumbs loop END
    return (
        <>
            <div id="home" className="hompage-slides-wrapper">
                <OwlCarousel
                    className="owl-theme homepage-slides"
                    items={1}
                    touchDrag={false}
                    margin={0}
                    mouseDrag={false}
                    smartSpeed={1000}
                    dotData={true}
                    dotsContainer={".owl-thumbs"}
                >
                    {bannerOneData}
                </OwlCarousel>

                <div className="owl-thumbs">
                    {thumbdata}
                </div>
            </div>
        </>
    );
}
//Props Types
BannerOne.propTypes = {
    banneronesData: PropTypes.array,
    thumbsData: PropTypes.array
};

//Default Props
BannerOne.defaultProps = {
    banneronesData: [
        {
            BgClass: "single-slider-item slide-bg-1",
            // TopTitle: "Clean & Creative",
            Title: "Innovating and Building Tomorrow's Leaders",
            Content:
                "The mission of Academic Haven is to mentor teens by searching for small software projects that the interns work on for the duration of the summer. Academic Haven not only strives to teach interns about IT and Software but also works to push them in the right direction for their future career paths.",
            // BtnLink: "/#0",
            // BtnName: "get started"
        },
        {
            BgClass: "single-slider-item slide-bg-2",
            // TopTitle: "Clean & Creative",
            Title: "Innovating and Building Tomorrow's Leaders",
            Content:
             "We help individuals be the best versions of themselves and then to push them in the right direction of their chosen career paths.",
            // BtnLink: "/#0",
            // BtnName: "get started"
        },
        {
            BgClass: "single-slider-item slide-bg-3",
            // TopTitle: "Clean & Creative",
            Title: "Innovating and Building Tomorrow's Leaders",
            Content:
                "The mission of Academic Haven is to mentor teens by searching for small software projects that the interns work on for the duration of the summer. Academic Haven not only strives to teach interns about IT and Software but also works to push them in the right direction for their future career paths.",
            // BtnLink: "/#0",
            // BtnName: "get started"
        }
    ],
    thumbsData: [
        {
            ThumbIcon: "icofont-rocket-alt-1",
            ThumbTitle: "Mentored Internship",
            ThumbContent: "We provide interns with a structured and supportive learning environment where they can gain valuable skills and knowledge.",
        },
        {
            ThumbIcon: "icofont-box",
            ThumbTitle: "IT Consulting",
            ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
        // {
        //     ThumbIcon: "icofont-live-support",
        //     ThumbTitle: "Reliable Support",
        //     ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        // },
    ]
};

export default BannerOne;
