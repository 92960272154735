import { collection, query, getDocs, getFirestore } from "firebase/firestore";
import store from "../store";

export const getAlumniData = async () => {
  const db = getFirestore();
  const tm = query(collection(db, "alumni"));
  const querySnapshot = await getDocs(tm);
  const alumni = []
  querySnapshot.forEach((doc) => {

    alumni.push(doc.data())

  });
  store.alumni.set(alumni)

}
