import React from 'react';
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import store from '../store';
import { useEffect } from "react";
import { useHookstate } from "@hookstate/core"
import { getAlumniData } from '../services/alumni';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";



const Alumni = () => {
    const { alumni } = useHookstate(store)

    useEffect(() => {
        getAlumniData()
    }, [])
    //Team loop start
    const alumnidata = alumni.map((alum, index) => (


        <div className="team-box" key={index}>
            <img src={alum.image.get()} alt="Description" />
            <div className="box-content">
                <div className="box-inner-content">
                    <h3 className="title">{alum.name.get()}</h3>
                    <span className="post">{alum.position.get()}</span>
                    <ul className="icon">
                        {console.log("***", alum.name.get())}
                        <li><Link to={{pathname:alum.urls.facebook.get()}} target="_blank"><Icofont icon="icofont-facebook" /></Link ></li>
                        <li><Link to={{pathname:alum.urls.linkedin.get()}} target="_blank"><Icofont icon="icofont-linkedin" /></Link></li>
                        <li><Link to={{pathname:alum.urls.instagram.get()}} target="_blank"><Icofont icon="icofont-instagram" /></Link></li>
                    </ul>
                </div>
            </div>
        </div>
        
    ));

    return (
        <>
        <NavBar pageName="home"/>

        <section id="team" className="our-team ptb-100">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>Our Alums</h2>
                                    <span className="section-title-bg">Alumni</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

        <div className="grid-container margin">
                        {alumnidata}

                    </div>

                    </section>

                    <Footer/>

                    
                 </>


    )
    
    };
    

    export default Alumni;
