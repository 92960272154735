import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import Page from "react-page-loading-v2";
import { CSSTransition } from "react-transition-group";

//firebase
import config from './firebase-config.json';
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";
// Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

// Template CSS Style
import "../src/style.css";
import "./assets/css/responsive.css";
import "./assets/css/color/color-blue.css"

// Import Component
import Home from "./pages/Home";
import BlogOne from "./pages/BlogOne";
import BlogTwo from "./pages/BlogTwo";
import SingleBlog from "./pages/SingleBlog";
import ScrollUpBtn from "./components/ScrollUp";
import Alumni from "./components/alumnihp";


const App = () => {
    const app = initializeApp(config);
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_ID),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
    });
    const analytics = getAnalytics(app);

    useEffect(() => {
        logEvent(analytics, 'page_view');
    }, [])

    return (
        <div className="App">
            {/*<Page loader={"comet-spin"} color={"#0f90cd"} size={50}>*/}
            <div>
                <Route
                    render={({ location }) => (
                        <CSSTransition
                            key={location.key}
                            timeout={{ enter: 900, exit: 900 }}
                            classNames="fade"
                        >
                            <section className="route-section">
                                <Switch location={location}>
                                    <Route
                                        path="/blog-details"
                                        component={SingleBlog}
                                    />
                                    <Route
                                        path="/blog-two"
                                        component={BlogTwo}
                                    />
                                    <Route
                                        path="/blog-one"
                                        component={BlogOne}
                                    />
                                     <Route
                                        path="/alumnihp"
                                        component={Alumni}
                                    />
                                    <Route
                                        path="/"
                                        component={Home}
                                    />
                                    
                                    <Redirect to="/not-found" />
                                </Switch>
                            </section>
                        </CSSTransition>
                    )}
                />
                {/* ScrollUpBtn: src/components/ScrollUp.jsx */}
                <ScrollUpBtn />
            </div>
            {/*</Page>*/}
        </div>
    );
}
export default App;
