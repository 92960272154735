import { collection, getFirestore, addDoc } from "firebase/firestore";
export const sendMessage = (message, onSuccess, onFailure) => {
    const db = getFirestore();
    message.sentDate= new Date();
    addDoc(collection(db, "messages"), message)
      .then((d) => {
        if (onSuccess) {
          onSuccess("Message sent Successfully");
        }
      })
      .catch((error) => {
        console.log(error.message);
        // ..
        if (onFailure) {
          onFailure(error.message);
        }
      })
}