import { hookstate } from "@hookstate/core"

export const initial = {
    team_members: [{
        image: '',
        name: '',
        position: '',
        urls: {facebook: "https://www.facebook.com", linkedin: "https://www.linkedin.com", instagram: "https://www.instagram.com",},
    }],
    quotes: [{
        imageUrl:'',
        content:'',
        name:'',
        position:'',
    }],
    alumni: [{
        image: '',
        name: '',
        position: '',
        urls: {facebook: "https://www.facebook.com", linkedin: "https://www.linkedin.com", instagram: "https://www.instagram.com",},
    }],
        partners: [{
        partnerLink:'', 
        partnerLogo:'', 
    }],
}

const store = hookstate(initial)
export default store

