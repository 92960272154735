import React from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import {Link} from 'react-router-dom';

const Footer = (props) => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <p className="copyright">{props.copyrightText}</p>
                        </div>
                        <div className="col-md-7">
                            <div className="social-icons bottom">
                                <ul className="list-inline">
                                    <li>{props.socialTitle} </li>
                                    <li>
                                        <Link to={{ pathname:props.FacebookLink}} target="_blank">
                                            <Icofont icon="icofont-facebook"/>
                                        </Link> 
                                    </li>
                                    <li>
                                        <Link to={{pathname:props.TwitterLink}} target="_blank">
                                            <Icofont icon="icofont-twitter"/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
//Props Types
Footer.propTypes = {
    copyrightText: PropTypes.string,
    FacebookLink: PropTypes.string,
    TwitterLink: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
    copyrightText: `© ${new Date().getFullYear()} Academic Haven All Rights Reserved.`,
    socialTitle: "Follow Us On:",
    FacebookLink: "https://www.facebook.com/academichaven",
    TwitterLink: "https://twitter.com/AcademicHaven",
};
export default Footer;
