import { collection, query, getDocs, getFirestore } from "firebase/firestore";
import store from "../store";

export const retrieveTeamMembersData = async () => {
  const db = getFirestore();
  const tm = query(collection(db, "team_members"));
  const querySnapshot = await getDocs(tm);
  const team_members = []
  console.log("***")
  querySnapshot.forEach((doc) => {

    team_members.push(doc.data())

  });
  console.log(team_members)
  store.team_members.set(team_members)

}
