import React from 'react';

//Import Component
import NavBar from "../components/NavBar";
import BlogPost from "../components/blog/Single";
import Footer from "../components/Footer";

const SingleBlog = () => {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar/>

            {/* BlogPost: src/components/Blog/BlogPost.jsx */}
            <BlogPost/>

            {/* Footer: src/components/Footer.jsx */}
            <Footer/>
        </>
    );
}
export default SingleBlog;
