import React, { useEffect } from 'react';
import { useHookstate } from "@hookstate/core";
import PropTypes from "prop-types";
import OwlCarousel from "react-owl-carousel3";
import {Link} from 'react-router-dom';
import store from "../store";
import ScrollAnimation from 'react-animate-on-scroll';
import { getListOfPartners } from '../services/partners';

const Partners = (props) => {
    const { partners } = useHookstate(store)
    useEffect(() => {
        getListOfPartners()
    }, [])

    const partnerData = partners.map((partner, index) => (
        <div className="single-partner-logo" key={index}>
            <Link to={{pathname:partner.partnerLink.get()}} className="logo-preview" target="_blank">
                <img src={partner.partnerLogo.get()} alt="partnerLogo"/>
            </Link>
        </div>
    ));
    //Partner loop END
    return (
        <>
            <section className="our-partners ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{props.sectionTitle}</h2>
                                    <p>{props.sectionDescription}</p>
                                    <span className="section-title-bg">{props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row">
                        <OwlCarousel
                            className="owl-theme partners-slides"
                            dots={false}
                            loop={true}
                            margin={100}
                            autoplay={true}
                            smartSpeed={1000}
                            nav={true}
                            navText={[
                                "<i class='icofont-arrow-left'></i>",
                                "<i class='icofont-arrow-right'></i>"
                            ]}
                            responsive={{
                                0: {
                                    items: 2,
                                    margin: 30,
                                },
                                768: {
                                    items: 3
                                },
                                1200: {
                                    items: 5
                                }
                            }}
                        >
                            {partnerData}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    );
}

//Props Types
Partners.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    partnersData: PropTypes.array
};

//Default Props
Partners.defaultProps = {
    SectionbgTitle: "Partners",
    sectionTitle: "Our Partners",
    sectionDescription: "Here at Academic Haven we have several partners that assist us and we value for this. Without these partners we truly would not be able to function as a company and because of this we have listed them below.",
    
};
export default Partners;
