import React from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

const Services = (props) => {
    //Service loop start
    const servicedata = props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon}/>
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{props.sectionTitle}</h2>
                                    <p>{props.sectionDescription}</p>
                                    <span className="section-title-bg">{props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </>
    )
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    // sectionDescription:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.",

    servicesData: [
        {
            icon: "icofont-automation",
            heading: "Innovative Solutions",
            description:
                "We engage in creative thinking and frequently introduce novel ideas, methods, or products that diverge from conventional or traditional approaches."
        },
        
        {
            icon: "icofont-woman-in-glasses",
            heading: "Friendly Support",
            description:
                "We exhibit a user-centric and empathetic approach when aiding individuals and organization develop robust systems to solve critical issues."
        },
        
        {
            icon: "icofont-laptop-alt",
            heading: "Design & Development",
            description:
                "We convert concepts into practical solutions by employing creativity, problem-solving, meticulous planning, prototyping, testing, and iterative processes."
        },
    ]
};

export default Services;
