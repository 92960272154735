import React, {useState} from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import axios from "axios";
import {TextInput, ValidationForm} from "react-bootstrap4-form-validation";
import ScrollAnimation from 'react-animate-on-scroll';
import { sendMessage} from "../services/messages";

const Contact = (props) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        number: "",
        message: "",
        successMsg: ""
    });

    const handleForm = (formData) => {
        const onSuccess= (successMessage)=>{
            document.getElementById("contactForm").reset();
            setFormData({
                successMsg: successMessage
            });
        }
        sendMessage(formData, onSuccess)
    };

    const handleFields = e => setFormData({[e.target.name]: e.target.value});
    return (
        <>
            <section id="contact" className="contact-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{props.sectionTitle}</h2>
                                    <p>{props.sectionDescription}</p>
                                    <span className="section-title-bg">{props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="address-area">
                                {/*<div className="addess">*/}
                                {/*    <Icofont icon="icofont-google-map"/>*/}
                                {/*    <h4>{props.AddTitle}</h4>*/}
                                {/*    <p>{props.Address}</p>*/}
                                {/*</div>*/}
                                <div className="email">
                                    <Icofont icon="icofont-email"/>
                                    <h4>{props.EmailTitle}</h4>
                                    <p>{props.Email}</p>
                                </div>
                                {/*<div className="phone">*/}
                                {/*    <Icofont icon="icofont-phone"/>*/}
                                {/*    <h4>{props.PhoneTitle}</h4>*/}
                                {/*    <p>{props.Phone}</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="contact-form">
                                <ValidationForm
                                    id="contactForm"
                                    onSubmit={(e, formData, inputs) => {
                                        e.preventDefault();
                                    console.log(formData)
                                        handleForm(formData);
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <TextInput
                                                    name="name"
                                                    id="name"
                                                    required
                                                    successMessage=""
                                                    errorMessage="Please enter your name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    autoComplete="off"
                                                    onChange={
                                                        handleFields
                                                    }
                                                />
                                                <div className="help-block with-errors"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <TextInput
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    required
                                                    successMessage=""
                                                    errorMessage="Please enter your email address"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    onChange={
                                                        handleFields
                                                    }
                                                />
                                                <div className="help-block with-errors"/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <TextInput
                                                    name="subject"
                                                    id="subject"
                                                    type="text"
                                                    successMessage=""
                                                    errorMessage="Please enter your email subject"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    autoComplete="off"
                                                    onChange={
                                                        handleFields
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <TextInput
                                                    name="number"
                                                    id="number"
                                                    type="text"
                                                    successMessage=""
                                                    errorMessage="Please enter your phone number"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    autoComplete="off"
                                                    onChange={
                                                        handleFields
                                                    }
                                                />
                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <TextInput
                                                    name="message"
                                                    id="description"
                                                    multiline
                                                    placeholder="Your message"
                                                    className="form-control"
                                                    required
                                                    successMessage=""
                                                    errorMessage="Please write your message"
                                                    rows="5"
                                                    autoComplete="off"
                                                    onChange={
                                                        handleFields
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="center-wrap">
                                        <div className="button">
                                            <button type="submit">Submit <Icofont icon="icofont-long-arrow-right"/>
                                            </button>
                                            <div className="mask"></div>
                                        </div>
                                    </div>
                                    <div className="clearfix"/>
                                </ValidationForm>
                                {formData.successMsg !== "" ? (
                                    <div className="col-md-12">
                                        <div id="contact_send_status">
                                            <h3 className="contactMsg">
                                                {formData.successMsg}
                                            </h3>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

//Props Types
Contact.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
};

//Default Props
Contact.defaultProps = {
    SectionbgTitle: "Contact",
    sectionTitle: "Our Contact",
    sectionDescription: "",
    AddTitle: "Address",
    Address: "2750 Quadra Street Victoria, Canada.",
    EmailTitle: "Email",
    Email: "info@academichaven.com",
    PhoneTitle: "Phone",
    Phone: "+1-443-687-9268",
};
export default Contact;
