import React from 'react';
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import ScrollAnimation from 'react-animate-on-scroll';

const About = (props) => {
    //About loop start
    const aboutdata = props.aboutsData.map((about, index) => (
        <div className="col-lg-4 col-md-6" key={index}>
            <div className="about-info">
                <Icofont icon={about.Icon}/>
                <h3>{about.title}</h3>
                <p>{about.content}</p>
            </div>
        </div>
    ));
    //About loop END
    return (
        <>
            <section id="about" className="about-us ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{props.sectionTitle}</h2>
                                    <p>{props.sectionDescription}</p>
                                    <span className="section-title-bg">{props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row">
                        {aboutdata}
                    </div>
                </div>
            </section>
        </>
    );
}

//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    aboutsData: PropTypes.array
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "About",
    sectionTitle: "About Us",
    sectionDescription:
        "Founded in 2019, Academic Haven is committed to providing you the best of our services, with a focus on dependability. customer service, and dedication.",
    aboutsData: [
        {
            Icon: "icofont-direction-sign",
            title: "Our Approach",
            content: "We use industry standard project management methodologies to give timely and excellent delivery.",
        },
       
        {
            Icon: "icofont-users-social",
            title: "Our Rich Experience",
            content: "Over 20 years of combined work experience in leadership and various technologies. You can be a 100% sure that we will pick the best technology that will meet your need.",
        },
        
        {
            Icon: "icofont-unique-idea",
            title: "Flexible Development",
            content: "Our services are cost effective and systematic to ensure optimal customer satisfaction.",
        },
    ]
};

export default About;
