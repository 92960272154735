import { collection, query, where, getDocs, getFirestore, doc, setDoc, addDoc } from "firebase/firestore";
import store from "../store";

export const getListOfPartners = async () => {
    const db = getFirestore();
    const q = query(collection(db, "partners"));
    const querySnapshot = await getDocs(q);
    const partners = []
    querySnapshot.forEach((doc) => {
      partners.push(doc.data())
   
    });
    store.partners.set(partners)
   
  }