import React, {useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import Icofont from 'react-icofont';

const SearchModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    return (
        <>
            <button onClick={handleShow} className="search-toggle">
                <Icofont icon="icofont-search-1"/>
            </button>

            <Modal show={show} onHide={handleClose} className="search-modal text-center modal fade">
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="header-search-form">
                                <Form>
                                    <input type="text" placeholder="Search"/>
                                    <button><Icofont icon="icofont-search-1"/></button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SearchModal;
