import React from 'react';
import PropTypes from "prop-types";

const BlogBanner = (props) => {
    return (
        <>
            <div className="bread-cumbs-area bread-cumbs-bg">
                <div className="diplay-table">
                    <div className="display-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h1>{props.Title}</h1>
                                    <p>{props.Content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
//Props Types
BlogBanner.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
};

//Default Props
BlogBanner.defaultProps = {
    Title: "Blog with sidebar",
    Content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.",
};

export default BlogBanner;
